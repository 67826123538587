(function() {
  "use strict"

  if (isMobile) {
    var $splash = jQuery('#splash-screen')
    $splash.css({ height: $splash.height() })
  } else {
    // Handle the active tab on resize
    var resizeTimerId;
    var resize = (function resize () {
      // Cache value
      var $itemMore = jQuery('.menu__item-more')
      return function () {
        var $currentVisibleItems = jQuery('.menu__item--current:visible')

        if ($currentVisibleItems.length == 2) {
          $itemMore.removeClass('menu__item--current')
        } else if ($currentVisibleItems.length == 0) {
          $itemMore.addClass('menu__item--current')
        }
      }
    })()

    window.onresize = function(){
      clearTimeout(resizeTimerId)
      resizeTimerId = setTimeout(resize, 50)
    }
    // Execute early
    resize()
  }

  // Animate and scroll home screen
  var animOnScroll = { addEvents: function () {}, removeEvents: function () {} }

  jQuery("#click-to-enter").on('click', function(event) {
    var $target = jQuery(this.hash)

    animOnScroll.toggleDisable()

    jQuery('html, body').animate({
      scrollTop: $target.offset().top
    }, 800)

    setTimeout(function () {
      animOnScroll.toggleDisable()
      animOnScroll.scrollPage()
    }, 805)

    return false;
  })

  // Hijack nav anchors click event to show the animation between them
  jQuery('nav.menu').find('.menu__link').on('click', function(event) {
    var $this = jQuery(this)
    var $li = $this.parent()
    var isDropdown = $this.data('toggle') === 'dropdown'

    if (! $li.hasClass('menu__item--current')) {
      if (isDropdown) {
       jQuery('.menu__item--current').addClass('menu__item--ex-current')
      } else {
        setTimeout(function () {
          window.location = this.href
        }.bind(this), 255)
      }

      jQuery('.menu__item--current').removeClass('menu__item--current')
      $li.addClass('menu__item--current')
    }

    event.preventDefault()
  })

  jQuery('.dropdown').on('hidden.bs.dropdown', function () {
    var $exCurrent = jQuery('.menu__item--ex-current')
    if ($exCurrent.length) {
      jQuery('.menu__item--current').removeClass('menu__item--current')
      $exCurrent.toggleClass('menu__item--ex-current menu__item--current')
    }
  })
  jQuery('.navigation-dropdown').on('click', 'a', function (event) {
    jQuery('.menu__item--ex-current').removeClass('menu__item--ex-current')
  })

  // If a image grid exists (contact does not have one for example)
  var grid = document.getElementById('grid')
  var pano = document.getElementById('js-pano-container')
  if (grid) {
    // Animate grid items
    animOnScroll = new AnimOnScroll(grid, {
      minDuration : 0.4,
      maxDuration : 0.7,
      viewportFactor : 0.3,
      loaded: function (el) {
        jQuery(this.el).find('img[data-src]').each(function () {
          if (isMobile) {
            var path = this.dataset.src.split("/")
            var filename = path.pop()
            this.src = path.join("/") + "/mobile/" + filename
          } else {
            this.src = this.dataset.src
          }
        })

        jQuery('.hidden-footer').removeClass('hidden-footer')
      }
    })
  }

  if (grid || pano) {
    // Patch Lightbox to add overflow hidden on open
    var original = {}
    var events = {
      start: function () {
        jQuery('body').css('overflow-y', 'hidden')
      },
      end: function () {
        jQuery('body').css('overflow-y', '')
      }
    };

    ['start', 'end'].forEach(function (method) {
      original[method] = Lightbox.prototype[method];
      Lightbox.prototype[method] = function() {
        var result = original[method].apply(this, arguments)
        events[method](result)
      }
    })

    lightbox.option({
      showImageNumberLabel: true,
      albumLabel: '%1 / %2',
      positionFromTop: isMobile ? 100 : 50
    })

    // Fixed Header
    var $header = jQuery('.header-section')
    var $main   = $header.next()
    var headerOffset = $header.offset().top
    var $window = jQuery(window)

    if (! isMobile && ! isIpad) {
      $window.on('scroll', function () {
        var scrollTop = $window.scrollTop()

        if (scrollTop > headerOffset) {
          $header.addClass('fixed')
          $main.addClass('with-fixed')
        } else {
          $header.removeClass('fixed')
          $main.removeClass('with-fixed')
        }
      })
    }
  }
})()
